<template>
  <v-dialog
    value="true"
    :content-class="$vuetify.breakpoint.mdAndDown ? undefined : 'rounded-xl'"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '600' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>Teammitglied hinzufügen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center" class="mt-4">
          <v-col cols="10" class="px-2 pb-0">
            <v-select
              filled
              rounded
              v-model="t.rolle"
              :items="rollen"
              label="Rolle"
              :rules="[rules.required]"
            ></v-select>
            <v-text-field
              v-model="t.vorname"
              :rules="[rules.required, rules.name]"
              filled
              rounded
              label="Vorname"
            ></v-text-field>
            <v-text-field
              v-model="t.name"
              :rules="[rules.required, rules.name]"
              filled
              rounded
              label="Nachname"
            ></v-text-field>
          </v-col>
          <v-col cols="10" class="px-2 py-0">
            <v-text-field
              v-model="t.adresse"
              :rules="[rules.required, rules.street, rules.streetnr]"
              filled
              rounded
              label="Straße + Nr."
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="px-2 py-0">
            <v-text-field
              v-model="t.plz"
              :rules="[rules.required, rules.plz]"
              filled
              rounded
              label="Postleitzahl"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="px-2 py-0">
            <v-text-field
              v-model="t.ort"
              :rules="[rules.required, rules.name]"
              filled
              rounded
              label="Ort"
            ></v-text-field>
          </v-col>
          <v-col cols="10" class="px-2 py-0">
            <v-text-field
              v-model="t.nation"
              filled
              rounded
              label="Nation (optional)"
            ></v-text-field>
          </v-col>
          <v-col cols="10" class="px-2 py-0">
            <v-text-field
              v-model="t.telefon"
              :rules="[rules.required, rules.telefon]"
              filled
              rounded
              label="Telefon-/Mobilnummer"
            ></v-text-field>
          </v-col>
          <v-col cols="10" class="px-2 py-0">
            <v-dialog
              ref="geburtsdatumref"
              v-model="geburtsdatumdialog"
              :return-value.sync="geburtsdatum"
              persistent
              width="290px"
              content-class="rounded-xl"
              color="dark"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="parse_datum(geburtsdatum)"
                  label="Geburtsdatum"
                  filled
                  rounded
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-card dark class="rounded-xl">
                <v-toolbar :color="template.colors.primary">
                  <v-toolbar-title>
                    Geburtsdatum
                  </v-toolbar-title>
                </v-toolbar>
                <v-row justify="center">
                  <v-col cols="12">
                    <v-date-picker
                      locale="de"
                      v-model="geburtsdatum"
                      class="rounded-xl"
                      content-class="rounded-xl"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000,
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        rounded
                        :color="template.colors.primary"
                        @click="geburtsdatumdialog = false"
                      >
                        Abbrechen
                      </v-btn>
                      <v-btn
                        text
                        rounded
                        :color="template.colors.primary"
                        :style="`color: ${template.colors.inline_primary_text};`"
                        @click="
                          $refs.geburtsdatumref.save(geburtsdatum)
                          update()
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="10" class="px-2 py-0">
            <v-switch
              rounded
              v-model="geimpft"
              :label="
                geimpft ? 'Person ist geimpft.' : 'Person ist nicht geimpft.'
              "
              v-on:change="geimpft ? (geimpftdialog = true) : undefined"
            ></v-switch>
            <v-dialog
              ref="geimpftref"
              v-model="geimpftdialog"
              :return-value.sync="datum"
              persistent
              width="290px"
              content-class="rounded-xl"
              color="dark"
            >
              <template v-slot:activator="{ on, attrs }">
                <keep-alive>
                  <v-text-field
                    :value="parse_datum(impfdatum)"
                    v-if="geimpft == true"
                    label="Vollständig geimpft seit..."
                    :rules="[rules.required]"
                    filled
                    rounded
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </keep-alive>
              </template>
              <v-card dark class="rounded-xl">
                <v-toolbar :color="template.colors.primary">
                  <v-toolbar-title>
                    Vollständig geimpft seit...
                  </v-toolbar-title>
                </v-toolbar>
                <v-row justify="center">
                  <v-col cols="12">
                    <v-date-picker
                      locale="de"
                      first-day-of-week="1"
                      v-model="impfdatum"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        rounded
                        :color="template.colors.primary"
                        @click="
                          geimpftdialog = false
                          geimpft = false
                        "
                      >
                        Abbrechen
                      </v-btn>
                      <v-btn
                        text
                        rounded
                        :color="template.colors.primary"
                        :style="`color: ${template.colors.inline_primary_text};`"
                        @click="$refs.geimpftref.save(impfdatum)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="10" class="px-2 py-0" v-if="!geimpft">
            <v-switch
              rounded
              v-model="genesen"
              :label="
                genesen ? 'Person ist genesen.' : 'Person ist nicht genesen.'
              "
              v-on:change="genesen ? (genesendialog = true) : update()"
            ></v-switch>
            <v-dialog
              ref="genesenref"
              v-model="genesendialog"
              :return-value.sync="datum"
              persistent
              width="290px"
              content-class="rounded-xl"
              color="dark"
            >
              <template v-slot:activator="{ on, attrs }">
                <keep-alive>
                  <v-text-field
                    :value="parse_datum(datum)"
                    v-if="genesen == true"
                    label="Genesen seit..."
                    :rules="[rules.required]"
                    filled
                    rounded
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </keep-alive>
              </template>
              <v-card dark class="rounded-xl">
                <v-toolbar :color="template.colors.primary">
                  <v-toolbar-title>Genesen seit...</v-toolbar-title>
                </v-toolbar>
                <v-row justify="center">
                  <v-col cols="12">
                    <v-date-picker
                      locale="de"
                      first-day-of-week="1"
                      v-model="datum"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        rounded
                        :color="template.colors.primary"
                        @click="
                          genesendialog = false
                          genesen = false
                        "
                      >
                        Abbrechen
                      </v-btn>
                      <v-btn
                        text
                        rounded
                        :color="template.colors.primary"
                        :style="`color: ${template.colors.inline_primary_text};`"
                        @click="$refs.genesenref.save(datum)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="10" class="px-2">
            <v-btn
              class="my-4"
              block
              rounded
              large
              :color="template.colors.primary"
              :style="`color: ${template.colors.inline_primary_text};`"
              @click="create()"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              Hinzufügen
            </v-btn>
            <v-btn
              class="my-4"
              block
              large
              outlined
              rounded
              :color="template.colors.primary"
              :style="`color: ${template.colors.inline_primary_text};`"
              @click="closing()"
            >
              Abbrechen
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from '../../../firebaseConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'Spieler-erstellen',
  data() {
    return {
      t: {
        vorname: '',
        name: '',
        adresse: '',
        plz: '',
        ort: '',
        nation: 'Deutschland',
        telefon: '',
      },
      geburtsdatum: '',
      genesen: false,
      geimpft: false,
      genesendialog: false,
      geimpftdialog: false,
      geburtsdatumdialog: false,
      datum: '',
      impfdatum: '',
      rollen: ['Sportler', 'Trainer', 'Betreuer'],
      rules: {
        required: (value) => !!value || 'Bitte gebe deine Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
      },
      valid: false,
    }
  },
  mounted() {
    this.t.verein = this.user.data.verein.name
  },
  computed: {
    ...mapGetters({
      user: 'user',
      team: 'team',
      template: 'template',
    }),
  },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
    generateAnzeigename() {
      this.t.anzeigename = this.t.verein + ' ' + this.t.name
    },
    parse_datum(datum) {
      if (datum) {
        var d = datum.split('-')
        return d[2] + '.' + d[1] + '.' + d[0]
      } else {
        return datum
      }
    },
    create() {
      var genesen = new Zeit(this.genesen, '00:00:00')
      if (this.$refs.form.validate()) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Team')
          .doc(this.team.id)
          .collection('Mitglieder')
          .add({
            vorname: this.t.vorname.trim(),
            name: this.t.name.trim(),
            adresse: this.t.adresse.trim(),
            plz: this.t.plz.trim(),
            ort: this.t.ort.trim(),
            nation: this.t.nation ? this.t.nation.trim() : '',
            telefon: this.t.telefon.trim(),
            rolle: this.t.rolle != 'Sportler' ? this.t.rolle.trim() : '',
            geburtsdatum: this.geburtsdatum || '',
            geimpft: this.geimpft,
            genesen: this.genesen
              ? {
                  datum: this.genesen,
                  timestamp: genesen.generateTimestamp(),
                }
              : false,
            impfdatum: this.impfdatum,
          })
          .then((doc) => {
            this.closing()
          })
          .catch((error) => {
            console.log('Es ist ein Fehler aufgetreten!', error)
          })
      }
    },
  },
}
</script>
