<template>
  <v-dialog
    value="true"
    :content-class="$vuetify.breakpoint.mdAndDown ? '' : 'rounded-xl'"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '600' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>Gast hinzufügen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-tabs centered fixed-tabs>
        <v-tab>Neue Person</v-tab>
        <v-tab-item>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row justify="center" class="mt-4">
              <v-col cols="10" class="px-2 pb-0">
                <v-text-field
                  v-model="t.vorname"
                  :rules="[rules.required, rules.name]"
                  filled
                  rounded
                  label="Vorname"
                ></v-text-field>
                <v-text-field
                  v-model="t.name"
                  :rules="[rules.required, rules.name]"
                  filled
                  rounded
                  label="Nachname"
                ></v-text-field>
              </v-col>
              <v-col cols="10" class="px-2 py-0">
                <v-text-field
                  v-model="t.adresse"
                  :rules="[rules.required, rules.street, rules.streetnr]"
                  filled
                  rounded
                  label="Straße + Nr."
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="px-2 py-0">
                <v-text-field
                  v-model="t.plz"
                  :rules="[rules.required, rules.plz]"
                  filled
                  rounded
                  label="Postleitzahl"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="px-2 py-0">
                <v-text-field
                  v-model="t.ort"
                  :rules="[rules.required, rules.name]"
                  filled
                  rounded
                  label="Ort"
                ></v-text-field>
              </v-col>
              <v-col cols="10" class="px-2 py-0">
                <v-text-field
                  v-model="t.telefon"
                  :rules="[rules.required, rules.telefon]"
                  filled
                  rounded
                  label="Telefon-/Mobilnummer"
                ></v-text-field>
              </v-col>
              <v-col cols="10" class="px-2">
                <ggswitches
                  :geimpft="false"
                  :getestet="false"
                  :genesen="false"
                  :datum="false"
                  @valid="validate_ggg($event)"
                  @change="get_ggg_data($event)"
                ></ggswitches>
              </v-col>
              <v-col cols="10" class="px-2">
                <v-btn
                  class="my-4"
                  block
                  large
                  rounded
                  :disabled="!ggg_valid"
                  :color="template.colors.primary"
                  :loading="loading"
                  @click="create()"
                >
                  <v-icon class="mr-2">mdi-plus</v-icon>
                  Hinzufügen
                </v-btn>
                <v-btn
                  class="my-4"
                  block
                  large
                  outlined
                  rounded
                  :color="template.colors.primary"
                  @click="closing()"
                >
                  Abbrechen
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
        <v-tab>Im Verein suchen</v-tab>
        <v-tab-item>
          <v-row justify="center" class="mt-4" v-if="!searched">
            <v-col cols="10" class="px-2 pb-0">
              <v-text-field
                v-model="searchdata.vorname"
                :rules="[rules.required, rules.name]"
                filled
                rounded
                label="Vorname"
              ></v-text-field>
              <v-text-field
                v-model="searchdata.nachname"
                :rules="[rules.required, rules.name]"
                filled
                rounded
                label="Nachname"
              ></v-text-field>
            </v-col>
            <v-col cols="10" class="px-2">
              <v-btn
                class="my-4"
                block
                large
                rounded
                :color="template.colors.primary"
                :loading="searching"
                @click="search()"
              >
                <v-icon class="mr-2">mdi-magnify</v-icon>
                Suchen
              </v-btn>
              <v-btn
                class="my-4"
                block
                large
                outlined
                rounded
                :color="template.colors.primary"
                @click="closing()"
              >
                Abbrechen
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-4" v-if="searched">
            <v-col cols="10">
              <v-row>
                <v-col align-self="center">
                  <h2>Suchergebnisse:</h2>
                </v-col>
                <v-col cols="auto" align-self="center">
                  <v-btn
                    large
                    :color="template.colors.primary"
                    rounded
                    @click="searched = false"
                  >
                    <v-icon class="mr-2">mdi-reload</v-icon>
                    Neue Suche
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="10" v-if="search_result.length == 0">
              <h4>Keine Person gefunden</h4>
            </v-col>
            <v-col
              cols="10"
              v-for="(result, index) in search_result"
              :key="index"
            >
              <v-card
                class="rounded-xl pa-4"
                :color="
                  selected_result == index ? '#63b32f' : template.colors.blocks
                "
                :light="
                  selected_result == index
                    ? !template.isDark('#63b32f')
                    : !template.isDark(template.colors.blocks)
                "
                :dark="
                  selected_result == index
                    ? template.isDark('#63b32f')
                    : template.isDark(template.colors.blocks)
                "
                @click="switch_result(index)"
              >
                <v-row>
                  <v-col cols="auto" align-self="center">
                    <v-icon large>
                      {{
                        selected_result == index
                          ? 'mdi-check-circle'
                          : 'mdi-account-circle'
                      }}
                    </v-icon>
                  </v-col>
                  <v-col align-self="center">
                    <h3 class="mb-0">
                      {{ result.mitglied.vorname }} {{ result.mitglied.name }}
                    </h3>
                    <small>
                      {{ result.team.name }}
                      {{
                        result.mitglied.rolle
                          ? ' - ' + result.mitglied.rolle
                          : ''
                      }}
                    </small>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="10" class="px-2">
              <ggswitches
                :geimpft="false"
                :getestet="false"
                :genesen="false"
                :datum="false"
                @valid="validate_ggg($event)"
                @change="get_ggg_data($event)"
              ></ggswitches>
            </v-col>
            <v-col cols="10" class="text-center">
              <v-btn
                large
                :color="template.colors.primary"
                rounded
                :disabled="selected_result < 0 || !ggg_valid"
                @click="createFromResult()"
              >
                Als Gast-Sportler hinzufügen
              </v-btn>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from '../../../firebaseConfig'
import { mapGetters } from 'vuex'
import ggswitches from '../2G-Regel/switches.vue'

export default {
  name: 'Spieler-erstellen',
  components: {
    ggswitches,
  },
  data() {
    return {
      t: {
        vorname: '',
        name: '',
        adresse: '',
        plz: '',
        ort: '',
        telefon: '',
      },
      searchdata: {
        vorname: '',
        nachname: '',
      },
      geburtsdatum: '',
      geburtsdatumdialog: false,
      impfdatum: '',
      ggg: {
        getestet: false,
        geimpft: false,
        genesen: false,
      },
      search_result: [],
      ggg_valid: false,
      selected_result: -1,
      searched: false,
      loading: false,
      searching: false,
      rules: {
        required: (value) => !!value || 'Bitte gebe deine Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
      },
      valid: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      team: 'team',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  methods: {
    validate_ggg(daten) {
      this.ggg_valid = daten
    },
    get_ggg_data(daten) {
      if (daten) {
        this.ggg = {
          getestet: daten.ggg.getestet || false,
          geimpft: daten.ggg.geimpft || false,
          genesen: daten.ggg.genesen || false,
          geburtsdatum: daten.geburtsdatum || false,
        }
      }
    },
    parse_datum(datum) {
      if (datum) {
        var d = datum.split('-')
        return d[2] + '.' + d[1] + '.' + d[0]
      } else {
        return datum
      }
    },
    closing() {
      this.$emit('closing', true)
    },
    generateAnzeigename() {
      this.t.anzeigename = this.t.verein + ' ' + this.t.name
    },
    switch_result(index) {
      if (this.selected_result == index) {
        this.selected_result = -1
      } else {
        this.selected_result = index
      }
    },
    async search() {
      this.searching = true
      this.search_result = []
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .get()
        .then(async (snap) => {
          await snap.forEach(async (team) => {
            var t = team.data()
            t.id = team.id
            await team.ref
              .collection('Mitglieder')
              .where('vorname', '==', this.searchdata.vorname)
              .where('name', '==', this.searchdata.nachname)
              .get()
              .then(async (teamsnap) => {
                await teamsnap.forEach((member) => {
                  var m = member.data()
                  m.id = member.id
                  this.search_result.push({
                    mitglied: m,
                    team: t,
                  })
                })
              })
          })
          this.searching = false
          this.searched = true
        })
    },
    createFromResult() {
      if (this.selected_result >= 0) {
        this.loading = true
        var event1 = this.veranstaltung.data
        event1.id = this.veranstaltung.id
        var zutritt = this.veranstaltung.data.beginn
        var verlassen = this.veranstaltung.data.ende
        var daten = {
          verein: {
            id: this.user.data.uid,
            name: this.user.data.verein.name,
            team: this.veranstaltung.data.mannschaft,
            gastspieler: true,
          },
          veranstaltung: event1,
          aktivitaet: 'Sportler',
          sportstaette: {
            name: this.veranstaltung.data.sportstaette
              ? this.veranstaltung.data.sportstaette
              : '',
          },
          person: {
            vorname: this.search_result[this.selected_result].mitglied.vorname,
            name: this.search_result[this.selected_result].mitglied.name,
            adresse: this.search_result[this.selected_result].mitglied.adresse,
            plz: this.search_result[this.selected_result].mitglied.plz,
            ort: this.search_result[this.selected_result].mitglied.ort,
            telefon: this.search_result[this.selected_result].mitglied.telefon,
          },
          ggg: this.ggg,
          zutritt: zutritt,
          verlassen: verlassen,
        }

        if (this.veranstaltung.data.gastgeberverein) {
          firebase
            .firestore()
            .collection('User')
            .doc(this.veranstaltung.data.gastgeberverein.id)
            .collection('Veranstaltung')
            .doc(this.veranstaltung.data.gastgeberverein.veranstaltung)
            .get()
            .then((gastgeberEvent) => {
              var event = gastgeberEvent.data()
              daten.veranstaltung = event
              daten.veranstaltung.id = gastgeberEvent.id
              daten.sportstaette = {
                name: event.sportstaette,
              }
              firebase
                .firestore()
                .collection('User')
                .doc(this.veranstaltung.data.gastgeberverein.id)
                .collection('Anwesenheit')
                .add(daten)
                .then((ref) => {
                  daten.veranstaltung = event1
                  daten.verein.gastspieler = ref.id
                  firebase
                    .firestore()
                    .collection('User')
                    .doc(this.user.data.uid)
                    .collection('Anwesenheit')
                    .add(daten)
                    .then((doc) => {
                      this.closing()
                    })
                    .catch((error) => {
                      console.log('Es ist ein Fehler aufgetreten!', error)
                      this.loading = false
                    })
                })
                .catch((error) => {
                  console.log('Es ist ein Fehler aufgetreten!', error)
                  this.loading = false
                })
            })
        } else {
          firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .collection('Anwesenheit')
            .add(daten)
            .then((doc) => {
              this.closing()
            })
            .catch((error) => {
              console.log('Es ist ein Fehler aufgetreten!', error)
              this.loading = false
            })
        }
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        this.loading = true
        var event1 = this.veranstaltung.data
        event1.id = this.veranstaltung.id
        var zutritt = new Zeit(
          this.veranstaltung.data.beginn.datum,
          this.veranstaltung.data.beginn.uhrzeit,
        )
        var verlassen = new Zeit(
          this.veranstaltung.data.ende.datum,
          this.veranstaltung.data.ende.uhrzeit,
        )
        var daten = {
          verein: {
            id: this.user.data.uid,
            name: this.user.data.verein.name,
            team: this.veranstaltung.data.mannschaft,
            gastspieler: true,
          },
          veranstaltung: event1,
          aktivitaet: 'Sportler',
          sportstaette: {
            name: this.veranstaltung.data.sportstaette
              ? this.veranstaltung.data.sportstaette
              : '',
          },
          person: {
            vorname: this.t.vorname.trim(),
            name: this.t.name.trim(),
            adresse: this.t.adresse.trim(),
            plz: this.t.plz.trim(),
            ort: this.t.ort.trim(),
            telefon: this.t.telefon.trim(),
          },
          zutritt: {
            datum: zutritt.getDatum(),
            uhrzeit: zutritt.getUhrzeit(),
            timestamp: zutritt.getTimestamp(),
          },
          verlassen: {
            datum: verlassen.getDatum(),
            uhrzeit: verlassen.getUhrzeit(),
            timestamp: verlassen.getTimestamp(),
          },
          ggg: this.ggg,
        }

        if (this.veranstaltung.data.gastgeberverein) {
          firebase
            .firestore()
            .collection('User')
            .doc(this.veranstaltung.data.gastgeberverein.id)
            .collection('Veranstaltung')
            .doc(this.veranstaltung.data.gastgeberverein.veranstaltung)
            .get()
            .then((gastgeberEvent) => {
              var event = gastgeberEvent.data()
              daten.veranstaltung = event
              daten.veranstaltung.id = gastgeberEvent.id
              daten.sportstaette = {
                name: event.sportstaette,
              }
              firebase
                .firestore()
                .collection('User')
                .doc(this.veranstaltung.data.gastgeberverein.id)
                .collection('Anwesenheit')
                .add(daten)
                .then((ref) => {
                  daten.veranstaltung = event1
                  daten.verein.gastspieler = ref.id
                  firebase
                    .firestore()
                    .collection('User')
                    .doc(this.user.data.uid)
                    .collection('Anwesenheit')
                    .add(daten)
                    .then((doc) => {
                      this.closing()
                    })
                    .catch((error) => {
                      console.log('Es ist ein Fehler aufgetreten!', error)
                      this.loading = false
                    })
                })
                .catch((error) => {
                  console.log('Es ist ein Fehler aufgetreten!', error)
                  this.loading = false
                })
            })
        } else {
          firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .collection('Anwesenheit')
            .add(daten)
            .then((doc) => {
              this.closing()
            })
            .catch((error) => {
              console.log('Es ist ein Fehler aufgetreten!', error)
              this.loading = false
            })
        }
      }
    },
  },
}
</script>
