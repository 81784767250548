<template>
  <div>
    <v-row class="py-5 mt-5">
      <v-col cols="auto" align-self="center">
        <v-btn
          icon
          :to="`/wettkampf/${veranstaltung.id}/disziplinen/${$route.params.disziplin}`"
          exact
        >
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>
          mdi-podium
        </v-icon>
      </v-col>
      <v-col>
        <h3 class="white--text font-weight-light">
          {{ veranstaltung.data.art }}
        </h3>
        <h2
          class="font-weight-bold"
          :style="`color: ${template.colors.inline_primary_text};`"
        >
          {{ veranstaltung.data.name }} - {{ disziplin.name }}
          {{
            disziplin.altersklasse ? ' (' + disziplin.altersklasse + ')' : ''
          }}
        </h2>
        <h4 v-if="disziplin">
          {{ parseDate(disziplin.beginn.datum) }} |
          {{ disziplin.beginn.uhrzeit }} - {{ disziplin.ende.uhrzeit }} Uhr
        </h4>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          :dark="!template.light"
          :light="template.light"
          class="rounded-xl pa-4"
        >
          <v-row justify="center">
            <v-col cols="12" class="text-right" v-if="false">
              <span class="font-weight-light">SORTIERUNG:</span>
              <v-btn text @click="change_sort()">
                <v-icon class="mr-1">mdi-sort-ascending</v-icon>
                {{ sort == 'vorname' ? 'VORNAME' : 'Nachname' }}
              </v-btn>
            </v-col>
            <v-col cols="12" class="text-center">
              <h3 class="my-4 white--text" v-if="member.length == 0">
                Für diese
                {{ template.var.verband == 'DEU' ? 'Gruppe' : 'Disziplin' }}
                liegen noch keine Anmeldungen vor.
              </h3>
              <v-card
                v-for="(m, index) in member"
                :key="m.id"
                class="my-1"
                :color="m.anwesend ? 'success' : 'rgba(0,0,0,0.6)'"
                :loading="loading == m.id"
                @click="m.anwesend ? abwesend(index) : anwesend(index)"
              >
                <v-card-title>
                  <v-icon class="mr-4">
                    {{ m.anwesend ? 'mdi-check-bold' : 'mdi-check-outline' }}
                  </v-icon>
                  {{ m.startnummer ? m.startnummer + ' -' : '' }}
                  {{ m.person.vorname }} {{ m.person.name }}
                </v-card-title>
              </v-card>
              <h3
                class="my-4 white--text"
                v-if="anwesende_gastspieler.length > 0"
              >
                Gast-Sportler:
              </h3>
              <v-card
                v-for="gast in anwesende_gastspieler"
                :key="gast.id"
                class="my-1"
                color="success"
                @click="del(gast)"
              >
                <v-card-title>
                  <v-icon class="mr-4">
                    mdi-check-bold
                  </v-icon>
                  {{ gast.vorname }} {{ gast.name }}
                </v-card-title>
              </v-card>
              <v-dialog v-model="delguest" persistent max-width="400">
                <v-card
                  :dark="!template.light"
                  :light="template.light"
                  v-if="del_guest"
                >
                  <v-card-title class="text-justify">
                    Möchtest Du den Gast-Sportler
                    <br />
                    {{ del_guest.vorname + ' ' + del_guest.name }}
                    wirklich entfernen?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="delguest = false">
                      Abbrechen
                    </v-btn>
                    <v-btn
                      color="success"
                      text
                      :loading="deleting"
                      @click="deleteGast(del_guest.id)"
                    >
                      Gast entfernen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" class="mt-5 text-center pb-0" v-if="false">
              <cardbutton
                title="Teammitglied hinzufügen"
                :textcolor="template.colors.block_text"
                :bgcolor="template.colors.blocks"
                upper
                @click="newmember = true"
              />
              <CreateMember v-if="newmember" v-on:closing="newmember = false" />
            </v-col>
            <v-col cols="12" class="pt-0 text-center" v-if="false">
              <cardbutton
                title="Gast-Sportler hinzufügen"
                :textcolor="template.colors.inline_primary_text"
                :bgcolor="template.colors.primary"
                upper
                @click="newtempmember = true"
              />
              <CreateGuestMember
                v-if="newtempmember"
                v-on:closing="
                  newtempmember = false
                  aktualisiereListe()
                "
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      app
      timeout="4000"
      v-model="saved"
      multi-line
      color="success"
      centered
      rounded="rounded-xl"
      top
    >
      <h2>
        <v-icon large class="mr-2">mdi-calendar-sync</v-icon>
        Anwesenheitsliste aktualisiert
      </h2>
      <template v-slot:action="{ attrs }">
        <v-btn
          :dark="!template.light"
          icon
          v-bind="attrs"
          @click="saved = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from 'firebase'
import store from '../../../../../store'
import cardbutton from '../../../../../elements/cards/card-button'
import CreateMember from '../../../team/createMember'
import CreateGuestMember from '../../createGuestMember'
import router from '../../../../../routes'

export default {
  name: 'Team-Anwesenheit-bearbeiten',
  data() {
    return {
      member: [],
      anwesende_spieler: [],
      anwesende_gastspieler: [],
      newmember: false,
      newtempmember: false,
      delguest: false,
      del_guest: '',
      deleting: false,
      loading: false,
      saved: false,
      sort: 'vorname',
      sortierung: 'ID (aufsteigend)',
      disziplin: '',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  components: {
    CreateMember,
    CreateGuestMember,
    cardbutton,
  },
  mounted() {
    if (this.$route.params.veranstaltung) {
      if (this.veranstaltung.id != this.$route.params.veranstaltung) {
        store.dispatch('setVeranstaltung', this.$route.params.veranstaltung)
      }
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.$route.params.veranstaltung)
        .collection('Disziplin')
        .doc(this.$route.params.disziplin)
        .get()
        .then((doc) => {
          this.disziplin = doc.data()
          this.disziplin.id = doc.id
        })
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .collection('Anmeldung')
        .where(
          'disziplinen_ids',
          'array-contains',
          this.$route.params.disziplin,
        )
        .onSnapshot(
          (querySnap) => {
            this.member = []
            querySnap.forEach((doc) => {
              this.member.push(doc.data())
              var index = this.member.length - 1
              this.member[index].id = doc.id
              this.member[index].person.id = doc.id
            })
            this.aktualisiereListe()
          },
          (error) => {
            router.dispatch('/veranstaltung/' + $route.params.veranstaltung)
          },
        )
    } else {
      store.dispatch('closeVeranstaltung')
    }
  },
  methods: {
    sortieren() {
      function compareStartnrASC(a, b) {
        var aval = a.startnummer
        if (aval < 10) {
          aval = '0' + aval
        }
        var bval = b.startnummer
        if (bval < 10) {
          bval = '0' + bval
        }
        if (aval > bval) {
          return 1
        } else if (aval == bval) {
          if (a.person.name > b.person.name) {
            return 1
          } else {
            return -1
          }
        } else {
          return -1
        }
      }
      function compareStartnrDESC(a, b) {
        if (a.startnummer > b.startnummer) {
          return -1
        } else if (a.startnummer == b.startnummer) {
          if (a.person.name > b.person.name) {
            return 1
          } else {
            return -1
          }
        } else {
          return 1
        }
      }
      function compareNameASC(a, b) {
        if (a.person.name > b.person.name) {
          return 1
        } else {
          return -1
        }
      }
      function compareNameDESC(a, b) {
        if (a.person.name > b.person.name) {
          return -1
        } else {
          return 1
        }
      }
      function compareVornameASC(a, b) {
        if (a.person.vorname > b.person.vorname) {
          return 1
        } else {
          return -1
        }
      }
      function compareVornameDESC(a, b) {
        if (a.person.vorname > b.person.vorname) {
          return -1
        } else {
          return 1
        }
      }

      if (this.sortierung == 'ID (aufsteigend)') {
        this.member.sort(compareStartnrASC)
      }
      if (this.sortierung == 'ID (absteigend)') {
        this.member.sort(compareStartnrDESC)
      }
      if (this.sortierung == 'Name (aufsteigend)') {
        this.member.sort(compareNameASC)
      }
      if (this.sortierung == 'Name (absteigend)') {
        this.member.sort(compareNameDESC)
      }
      if (this.sortierung == 'Vorname (aufsteigend)') {
        this.member.sort(compareVornameASC)
      }
      if (this.sortierung == 'Vorname (absteigend)') {
        this.member.sort(compareVornameDESC)
      }
      this.select_sort = false
    },
    change_sort() {
      if (this.sort == 'vorname') {
        this.sort = 'person.name'
      } else {
        this.sort = 'person.vorname'
      }
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .collection('Anmeldung')
        .where(
          'disziplinen_ids',
          'array-contains',
          this.$route.params.disziplin,
        )
        .orderBy(this.sort)
        .onSnapshot((querySnap) => {
          this.member = []
          querySnap.forEach((doc) => {
            this.member.push(doc.data())
            var index = this.member.length - 1
            this.member[index].id = doc.id
            this.member[index].person.id = doc.id
          })
          this.aktualisiereListe()
        })
    },
    del(gast) {
      this.delguest = true
      this.del_guest = gast
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    anwesend(index) {
      this.loading = this.member[index].id
      var event = this.veranstaltung.data
      event.id = this.veranstaltung.id
      var zutritt = this.disziplin.beginn
      var verlassen = this.disziplin.ende

      var daten = {
        veranstaltung: event,
        disziplin: this.disziplin,
        aktivitaet: this.member[index].person.aktivitaet,
        sportstaette: {
          name: this.veranstaltung.data.sportstaette,
        },
        person: this.member[index].person,
        zutritt: zutritt,
        verlassen: verlassen,
      }

      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', this.veranstaltung.id)
        .where('disziplin.id', '==', this.disziplin.id)
        .where('person.id', '==', this.member[index].id)
        .get()
        .then((docRef) => {
          var exists = false
          docRef.forEach((doc) => {
            if (doc.exists) {
              exists = true
            }
          })
          console.log(exists)
          if (!exists) {
            firebase
              .firestore()
              .collection('User')
              .doc(this.user.data.uid)
              .collection('Anwesenheit')
              .add(daten)
              .then(() => {
                this.aktualisiereListe()
                this.loading = false
                this.saved = true
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
    },
    abwesend(index) {
      this.loading = this.member[index].id
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', this.veranstaltung.id)
        .where('disziplin.id', '==', this.disziplin.id)
        .where('person.id', '==', this.member[index].id)
        .get()
        .then((docRef) => {
          docRef.forEach((doc) => {
            doc.ref.delete().then(() => {
              this.aktualisiereListe()
              this.loading = false
              this.saved = true
            })
          })
        })
    },
    deleteGast(gast) {
      this.deleting = true
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .doc(gast.id)
        .delete()
        .then(() => {
          if (this.veranstaltung.data.gastgeberverein) {
            firebase
              .firestore()
              .collection('User')
              .doc(this.veranstaltung.data.gastgeberverein.id)
              .collection('Anwesenheit')
              .doc(this.veranstaltung.data.gastgeberverein.veranstaltung)
              .collection('Gastteam')
              .doc(id)
              .delete()
              .then(() => {
                this.aktualisiereListe()
                this.deleting = false
                this.delguest = false
                this.del_guest = ''
              })
          } else {
            this.aktualisiereListe()
            this.deleting = false
            this.delguest = false
            this.del_guest = ''
          }
        })
    },
    aktualisiereListe() {
      var i
      this.anwesende_spieler = []
      this.anwesende_gastspieler = []
      var liste = this.veranstaltung.anwesenheit
      for (i = 0; i < liste.length; i++) {
        if (liste[i].disziplin) {
          if (liste[i].disziplin.id == this.$route.params.disziplin) {
            this.anwesende_spieler.push(liste[i].person.id)
          }
        }
      }
      var member_old = this.member
      this.member = []
      for (i = 0; i < member_old.length; i++) {
        if (this.anwesende_spieler.includes(member_old[i].id)) {
          this.member.push(member_old[i])
          this.member[i].anwesend = true
        } else {
          this.member.push(member_old[i])
          this.member[i].anwesend = false
        }
      }
      this.sortieren()
    },
  },
}
</script>
